/* eslint-disable sonarjs/cognitive-complexity */

const IMAGE_MODE_ZOOM = 'zoom';
const IMAGE_MODE_CAROUSEL = 'carousel';
const GLOBAL_DIALOG = 'm-has_dialog';

const keyCode = Object.freeze({
    SPACE: 32,
    ENTER: 13
});

/**
 * @typedef {ReturnType<typeof import('widgets/product/ProductImages').default>} ProductImagesBase
 */

/**
 * @description ProductImages widget
 * @param {ProductImagesBase} ProductImagesBase Base widget for extending
 * @returns {typeof ProductImages} ProductImages class
 */

export default function (ProductImagesBase) {
    /**
     * @class ProductImages
     * @augments ProductImagesBase
     */
    class ProductImages extends ProductImagesBase {
        /**
         * @param {object} product - product object
         */
        renderImages(product) {
            if (product.video && product.images && product.images.large) {
                const imageLength = product.images.large.length;

                product.video = product.video.map(video => {
                    video.index += imageLength;

                    return video;
                });
            }

            super.renderImages(product);
        }

        /**
         * @description Does all the work to init photoswipe and show it
         * @param {typeof import('photoswipe')} PhotoSwipe - PhotoSwipe library class
         * @param {typeof import('photoswipe/dist/photoswipe-ui-default')} PhotoSwipeUI - PhotoSwipeUI_Default library class
         * @param {{originalWidth: string, originalHeight:string}} originalImageSize Object with original image width and height
         * @param {Number} page - Start image number if present, 0 by default
         */
        initAndShowZoom(PhotoSwipe, PhotoSwipeUI, { originalWidth, originalHeight }, page = null) {
            const pswpElement = document.querySelectorAll('.pswp')[0];
            const imgCarousel = this.getById('imagesCarousel', (/** @type {carousel} */ carousel) => carousel);
            const thumbnailsCarousel = this.getById('imagesThumbnails', (/** @type {carousel} */ thumbnails) => thumbnails);

            if (!imgCarousel || !(pswpElement instanceof HTMLElement)) {
                return;
            }

            const carouselImages = this.getCarouselImages();

            if (!carouselImages) {
                return;
            }

            pswpElement.setAttribute('data-tau', 'zoom_dialog');

            const items = Array.from(carouselImages)
                .filter(element => element.dataset.originalSrc)
                .map(element => {
                    return {
                        src: element.dataset.originalSrc || element.src,
                        w: originalWidth || element.naturalWidth,
                        h: originalHeight || element.naturalHeight
                    };
                });

            const carouselSlideHTML = imgCarousel.ref('elemCarouselTrack').get().querySelectorAll('.js-slide-html');

            Array.from(carouselSlideHTML)
                .forEach(element => {
                    if (element.innerHTML) {
                        items.push({
                            html: element.innerHTML
                        });
                    }
                });

            const prefs = this.prefs();

            if (!('zoomCloseElClasses' in prefs)) {
                Object.assign(prefs, {
                    thumbnailsPerFrame: 4,
                    thumbnailsZoomClass: 'm-zoomed-in',
                    classesGlobalDialog: GLOBAL_DIALOG,
                    zoomLoop: false,
                    zoomClickToCloseNonZoomable: false,
                    zoomCloseElClasses: '',
                    closeOnScroll: false,
                    predefinedHeight: 1773,
                    predefinedWidth: 1333,
                    barsSizeBottom: 'auto',
                    barsSizeTop: 24,
                    showHideOpacity: true,
                    ...super.prefs()
                });
            }

            const options = {
                index: page || 0,
                barsSize: { top: prefs.barsSizeTop, bottom: prefs.barsSizeBottom },
                loop: prefs.zoomLoop,
                history: false,
                clickToCloseNonZoomable: prefs.zoomClickToCloseNonZoomable,
                closeElClasses: prefs.zoomCloseElClasses.split(','),
                closeOnScroll: prefs.closeOnScroll,
                showHideOpacity: prefs.showHideOpacity,
                isClickableElement: function (/** @type {HTMLElement} */ el) {
                    return el.tagName === 'A'
                        || el.tagName === 'BUTTON';
                }
            };

            const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI, items, options);

            gallery.listen('close', () => this.onZoomClosed());

            gallery.listen('doubleTap', () => {
                const isZoomIn = gallery.getZoomLevel() === 1; // When double tap event reported we actually have previous state

                pswpElement.classList.toggle('pswp--zoomed-in', !isZoomIn);
            });

            gallery.listen('beforeChange', () => {
                const currentPage = gallery.getCurrentIndex();

                imgCarousel.scrollToPage(currentPage);

                if (thumbnailsCarousel) {
                    thumbnailsCarousel.markCurrentPage(currentPage).scrollIntoView();
                }
            });

            const updateCurrentItem = () => {
                const currentIndex = gallery.getCurrentIndex();

                if (currentIndex === 0) {
                    gallery.template.classList.add('pswp--first');
                } else {
                    gallery.template.classList.remove('pswp--first');
                }

                if (currentIndex === (gallery.items.length - 1)) {
                    gallery.template.classList.add('pswp--last');
                } else {
                    gallery.template.classList.remove('pswp--last');
                }

                if (gallery.currItem.html) {
                    gallery.template.classList.add('pswp--html');
                } else {
                    gallery.template.classList.remove('pswp--html');
                }
            };

            gallery.listen('afterInit', updateCurrentItem);
            gallery.listen('afterChange', updateCurrentItem);

            const zoomToOriginal = gallery.zoomTo;

            gallery.zoomTo = (destZoomLevel, centerPoint, speed, easingFn, updateFn) => {
                if (gallery.currItem.initialZoomLevel) {
                    if (destZoomLevel > gallery.currItem.initialZoomLevel) {
                        gallery.template.classList.add('pswp--zoomed');
                    } else {
                        gallery.template.classList.remove('pswp--zoomed');
                    }
                }

                zoomToOriginal.call(gallery, destZoomLevel, centerPoint, speed, easingFn, updateFn);
            };

            // Fix for console errors after destroing closed gallery
            const originalGalleryDestroy = gallery.destroy;

            gallery.destroy = () => {
                if (!gallery.destroyed) {
                    originalGalleryDestroy.call(gallery);
                    gallery.destroyed = true;
                }
            };

            gallery.init();

            this.gallery = gallery;
            this.imageMode = IMAGE_MODE_ZOOM;

            if (thumbnailsCarousel) {
                this.toggleZoomState(true);
            }

            this.addGlobalDialogClass();
            this.addFocusTrap();
        }

        /**
         * @description Click handler on image from large images carousel
         * @param {Element} elem - an Element where click happens
         * @param {number} page - Clicked image page number should send to gallery
         */
        onImageCarouselPageClicked(elem, page) {
            this.zoom(page);
        }

        /**
         * @description Generic method to open zoom popup
         * @param {number} page - Clicked image page number is sending to gallery
         */
        zoom(page = null) {
            this.loadPhotoswipeDependencies().then(([PhotoSwipe, PhotoSwipeUI, originalImageSize]) => {
                this.initAndShowZoom(PhotoSwipe.default, PhotoSwipeUI.default, originalImageSize, page);
            });
        }

        /**
         * @description "Close" photoswipe popup icon click handler
         * @listens dom#click
         */
        closeZoom() {
            if (this.gallery) {
                this.gallery.close();

                const thumbnailsCarousel = this.getById(
                    'imagesThumbnails',
                    thumbnails => thumbnails
                );

                if (thumbnailsCarousel) {
                    thumbnailsCarousel.updateCarouselMetric();
                    thumbnailsCarousel.updateCarouselState();
                }
            }

            this.eventBus().emit('zoom.closed', {});
        }

        /**
         * @description Click handler on image from large images carousel
         * @returns {Promise} - Promise that fulfills when all of the promises passed as an iterable have been fulfilled
         */
        loadPhotoswipeDependencies() {
            return Promise.all([
                import(/* webpackChunkName: 'photoswipe' */'photoswipe'),
                import(/* webpackChunkName: 'photoswipe' */'photoswipe/dist/photoswipe-ui-default.js'),
                this.getOriginalImageSize()
            ]);
        }

        /**
         * @description Get URL from data-original-src attribute.
         *  Load original image by URL. Get original size.
         *  The image element should have data-original-src attribute with original image URL.
         * @returns {Promise} return new Promise with image sizes
         */
        getOriginalImageSize() {
            return new Promise((resolve) => {
                const imgCarousel = this.getById('imagesCarousel', (carousel) => carousel);
                const predefinedSize = {
                    originalHeight: this.prefs().predefinedHeight,
                    originalWidth: this.prefs().predefinedWidth
                };

                if (!imgCarousel) {
                    resolve(predefinedSize);
                } else {
                    const firstImage = this.getCarouselImages()[0];

                    if (firstImage.dataset.originalSrc) {
                        const img = new Image();

                        img.addEventListener('load', () => resolve({ originalHeight: img.height, originalWidth: img.width }));
                        img.addEventListener('error', () => resolve(predefinedSize));
                        img.src = firstImage.dataset.originalSrc;
                    } else {
                        resolve(predefinedSize);
                    }
                }
            });
        }

        /**
         * @description Get list of main carousel product images
         * @returns {object} images
         */
        getCarouselImages() {
            const imgCarousel = this.getById('imagesCarousel', (carousel) => carousel);
            const imagesContainer = imgCarousel.ref(imgCarousel.prefs().elemCarouselTrack).get();

            return [].slice.call(imagesContainer?.querySelectorAll('img')) || [];
        }

        /**
         * @description Enter / space handler to initiate zoom popup
         * @listens dom#keydown
         * @param {Element} _el  event source element
         * @param {Event} event event instance if DOM event
         */
        handleKeydown(_el, event) {
            if (event.keyCode === keyCode.ENTER || event.keyCode === keyCode.SPACE) {
                event.preventDefault();
                event.stopPropagation();
                this.zoom();
            }
        }

        /**
         * @description Handler to sync main carousel with thumbnails carousel
         * @param {Element} el - event source element
         * @param {Event} page - current page number
         */
        onImageCarouselPageChanged(el, page) {
            this.getById('imagesThumbnails', (carousel) => {
                if (this.imageMode !== IMAGE_MODE_ZOOM) {
                    carousel
                        .markCurrentPage(page)
                        .scrollIntoView();
                }
            });
        }

        /**
         * @description Add Global Dialog Class
         */
        addGlobalDialogClass() {
            const html = this.ref('html');
            let classesGlobalDialog;

            if (!this.prefs().classesGlobalDialog) {
                classesGlobalDialog = GLOBAL_DIALOG;
            } else {
                classesGlobalDialog = this.prefs().classesGlobalDialog;
            }

            if (!html.hasClass(classesGlobalDialog)) {
                html.addClass(classesGlobalDialog);
            }
        }

        /**
         * @description Sets image mode / do some DOM modifications after zoom closed
         */
        onZoomClosed() {
            this.toggleZoomState(false);

            this.imageMode = IMAGE_MODE_CAROUSEL;
            this.removeGlobalDialogClass();

            if (this.backFocusElement) {
                this.backFocusElement = undefined;
            }
        }

        /**
         * @description Toggle Zoom state on Thumbnails carousel
         * @param {object} state flag for toggle zoom state
         */
        toggleZoomState(state) {
            let thumbnails = this.getById('imagesThumbnails',
                (thumbnailsCarousel) => thumbnailsCarousel);
            let thumbnailsZoomClass;

            if (!thumbnails) {
                thumbnails = this.getById('imagesCarousel', (thumbnailsCarousel) => thumbnailsCarousel);
            }

            if (!this.prefs().thumbnailsZoomClass) {
                thumbnailsZoomClass = 'm-zoomed-in';
            } else {
                thumbnailsZoomClass = this.prefs().thumbnailsZoomClass;
            }

            thumbnails.ref('self').toggleClass(thumbnailsZoomClass, state);
        }

        /**
         * @description Add focus trap functionality in the opened zoom dialog to prevent background elements focusing
         */
        addFocusTrap() {
            this.backFocusElement = document.activeElement instanceof HTMLElement ? document.activeElement : document.body;
        }

        /**
         * @description Remove Global Dialog Class
         */
        removeGlobalDialogClass() {
            let classesGlobalDialog;

            if (!this.prefs().classesGlobalDialog) {
                classesGlobalDialog = GLOBAL_DIALOG;
            } else {
                classesGlobalDialog = this.prefs().classesGlobalDialog;
            }

            this.ref('html').removeClass(classesGlobalDialog);
        }
    }

    return ProductImages;
}
